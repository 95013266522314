export const projects = [
    {
        projectName: '选股器',
        projectDes: '选股器产品是为金融投资者量身打造的，' +
            '可以根据用户指定的各种金融指标及其组合对股票进行筛选，' +
            '本产品易用且高效，可以大大提高金融投资者的效率。',
        imgName: 'xgq.png',
        href: '/xgq',
    },
    {
        projectName: '帮我盯着',
        projectDes: '帮我盯着是一个帮助用户实时监测最新分红数据的系统' +
            '它实时获取上交所发布的PDF公告，并分析获取PDF中分红送股转股内容' +
            '用户可以设置信息推送条件，' +
            '在当有满足用户需求的信息出现的时候，第一时间将报表信息发送给用户。',
        imgName: 'dingding.png',
        href: '/dingding',
    },
    {
        projectName: '期查查',
        projectDes: '关注期货市场的平台，其目标是通过分析期货的原生数据，并将原生' +
            '数据进行处理，以图的形式展现给用户，辅助用户挖掘期货交易中' +
            '潜在的规律。除此之外，还为用户提供完整的数据，供' +
            '用户自主研究。',
        imgName: 'futures.png',
        href: '/futures',
    },
    {
        projectName: '股查查',
        projectDes: '将市场原生数据，进行数据处理，通过页面展示出北向资金、行业趋势、机构动态等股票市场信息，帮助投资者更好的实现智能选股的平台。',
        imgName: 'logo.png',
        href: 'http://csubigdata.com:8020',
    },
    // {
    //   projectName: '涨停预测',
    //   projectDes: '使用机器学习模型对深圳以及上海股票市场的涨停事件进行预测，' +
    //       '并提供正确率、收益率等相关指标的统计。',
    //   imgName: 'limit.png',
    //   href: '/limit',
    // },
]
