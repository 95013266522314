<template>
  <div class='card' @click="toProject">
    <el-card shadow="hover">
      <div class="title" slot="header">
        <img :src="imgSrc" alt="img">
        <h3>{{ project.projectName }}</h3>
      </div>
      <div class="description">
        <p>{{ project.projectDes }}</p>
      </div>
    </el-card>
  </div>
</template>

<script>
import {projects} from "@/data/statics";

export default {
  name: "ProjectCard",
  props: {
    project: {
      type: Object,
    },
  },
  data() {
    return {
      imgSrc: require('@/assets/' + this.project.imgName)
    }
  },
  methods: {
    toProject() {
      console.log('adf');
      window.location.href = this.project.href
    }
  }

}
</script>

<style scoped>

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

img {
  max-width: 100px;
  max-height: 50px;
  margin: 4px;
  padding-right: 4px;
}

.card {
  cursor: pointer;
}

.description {
  margin: 5px;
}

.el-card {
  width: 350px;
  height: 360px;
  margin-bottom: 15px;
}
</style>
