<template>
  <div id="app">
    <header class="header">
      <a href="http://www.csu.edu.cn/">
        <img src="@/assets/csu2.jpg" class="logo" alt="CSU">
      </a>
<!--      <span>大数据组</span>-->
    </header>
    <div class="main">
      <el-card>
        <div class="panel">
          <h3 class="title">项目列表</h3>

          <!--          <el-carousel :interval="4000" type="card">-->
          <!--            <el-carousel-item v-for="project in projects">-->
          <!--              <project-card :project="project"></project-card>-->
          <!--            </el-carousel-item>-->
          <!--          </el-carousel>-->
          <div class="cards">
            <project-card v-for="project in projects" :project="project"></project-card>
          </div>
        </div>
      </el-card>
    </div>
    <TheFooter class="footer"/>
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter";
import ProjectCard from "@/components/ProjectCard";
import {projects} from "@/data/statics";

export default {
  name: 'App',
  components: {
    ProjectCard,
    TheFooter,
  },
  data() {
    return {
      projects,
    }
  }
}
</script>

<style>
#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
}

.main {
  /*background-color: aliceblue;*/
  flex: 1 0 auto;
}

.footer {
  border-radius: 4px;
  flex: 0 0 auto;
}

.panel {
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
}

.cards {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 40px;
}

.logo {
  max-height: 100px;
  max-width: 200px;
}

</style>
